<template>
  <div>
    <cta-banner :title="$route.name"/>
    <div class="container mx-auto flex justify-center" v-if="eventoSeleccionado.afiche">
      <img class="h-full" :src="eventoSeleccionado.afiche" :alt="eventoSeleccionado.nombre">
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex"
import CtaBanner from '../../components/common/CtaBanner.vue';

export default {
  components: {
    CtaBanner,
  },
  computed: {
    ...mapState(["idEventoActual"]),
    ...mapGetters(["eventoSeleccionado"])
  },
}
</script>
